import React from "react";
import logo from "./images/logo.png";
import App from "./App";
import ReactDOM from "react-dom";

function SetFormato(valor = 0){
  return new Intl.NumberFormat("en-US").format(valor)+".00"
}

function round(num) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return Math.round(m) / 100 * Math.sign(num);
}


export default class Appereance extends React.Component{

  constructor(props) {
    super();
    this.state = {showForm:false}
  }

  componentDidMount() {
    this.setDescription();

    this.setSuccess();

  }

  setDescription(){
    //colocamos la descripcion que obtivimos del get desc
    document.getElementById("description").textContent = this.getParameterByName("desc")
  }


  setSuccess(){
    document.querySelector(".success").textContent = this.getParameterByName("success")
  }

  handleChange(evt){
    var cantidad = Number(this.getParameterByName("amount"))
    var boletos = evt.target.value;
    var total = cantidad * boletos;

    document.querySelector(".cantidad").textContent = SetFormato(total)+" MXN"
    document.querySelector(".cantidad").setAttribute("value",total)

    this.setRetribution()

    document.querySelector(".checbox-retribucion").checked = false


  }

  getParameterByName(name) {
    if (name !== "" && name !== null && name != undefined) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
          results = regex.exec(window.location.search);
      return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    } else {
      var arr = window.location.href.split("/");
      return arr[arr.length - 1];
    }

  }

  handleClick(evt){
    var bandera = true;
    let parameters = new Appereance();

    document.querySelectorAll(".datas-container input,.datas-container select").forEach(element=>{
      if (element.classList.contains("is-invalid")){
        bandera = false;
      }
    })

    if (!bandera){
      alert("Algunos elementos no han sido llenados correctamente, por favor verifica que todos los campos son de color verde e intentalo de nuevo.")
    }

    var cantidad = document.querySelector("#amount").value;
    cantidad = cantidad.replaceAll(" MXN","")
    cantidad = cantidad.replaceAll(",","")
    cantidad = cantidad.replaceAll("$","")
    cantidad = cantidad.replace(".00","")

    let formData = new FormData()

    formData.append("firstname", document.getElementById("nombre").value);
    formData.append("email",document.getElementById("correo").value);
    if (document.getElementById("telefono").value.length > 0){
      formData.append("phone",document.getElementById("telefono").value)
    }
    formData.append("61",cantidad);
    formData.append("111",cantidad);



    if(parameters.getParameterByName("r_utm_campaign") != null){
      formData.append("71",parameters.getParameterByName("r_utm_campaign"));
    }else{
      if(parameters.getParameterByName("utm_campaign") != null){
        formData.append("71",parameters.getParameterByName("utm_campaign"));
      }
    }

    if(parameters.getParameterByName("r_tipo_de_donante") !== null){
      formData.append("41",parameters.getParameterByName("r_tipo_de_donante"));
    }else{
      if(parameters.getParameterByName("tipo_de_donante") !== null){
        formData.append("41",parameters.getParameterByName("tipo_de_donante"));
      }
    }

    if(parameters.getParameterByName("r_utm_source") != null){
      formData.append("72",parameters.getParameterByName("r_utm_source"));
    }else{
      if(parameters.getParameterByName("utm_source") != null){
        formData.append("72",parameters.getParameterByName("utm_source"));
      }
    }

    if(parameters.getParameterByName("utm_content") != null){
      formData.append("74",parameters.getParameterByName("utm_content"));
    }

    if(parameters.getParameterByName("r_utm_term") != null){
      formData.append("101",parameters.getParameterByName("r_utm_term"));
    }else{
      if(parameters.getParameterByName("utm_term") != null){
        formData.append("101",parameters.getParameterByName("utm_term"));
      }
    }

    if(parameters.getParameterByName("r_utm_medium") != null){
      formData.append("73",parameters.getParameterByName("r_utm_medium"));
    }else{
      if(parameters.getParameterByName("utm_medium") != null){
        formData.append("73",parameters.getParameterByName("utm_medium"));
      }
    }



    fetch('https://api.savethechildrenmx.org/save_contact.php',{
      method: 'POST',
      body: formData
    }).then(resp=>{
      if(resp.status === 200){
        ReactDOM.render(<App/>, document.querySelector(".app-container"));
        document.querySelector(".app-container").style.display = "flex"
        document.querySelector(".datas-container").style.display = "none"
        evt.target.style.display = "none"
      }else{
        console.error(resp.statusText)
      }
      return resp.text();
    }).then(txt =>{
      console.log(txt)
    })
  }

  verifyState(){
    var flag = true;

    document.querySelectorAll(".datas-container .form-group input,.datas-container .form-group select ").forEach(element=>{
      if(element.classList.contains("is-invalid") || !element.classList.contains("is-valid")){
        flag = false;
      }
    })

    if (flag){
      document.querySelector(".button-continuar").removeAttribute("disabled")
      document.querySelector(".button-continuar").addEventListener("click",this.handleClick)
    }else{
      document.querySelector(".button-continuar").setAttribute("disabled","disabled")
    }
  }

  handleNombre(evt){
    if(evt.target.value[evt.target.value.length -1] == ' ')
    {
      evt.target.value = evt.target.value.substring(0,evt.target.value.length-1)
    }

    if(evt.target.value[0] == ' ')
    {
      evt.target.value = evt.target.value.substring(1,evt.target.value.length-1)
    }
    evt.target.value=evt.target.value.replaceAll("  "," ")

    var regex = new RegExp("^[^\\d]+$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display=""
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display="flex"
    }

    this.verifyState();
  }

  handleCorreo(evt){
    if(evt.target.value[evt.target.value.length -1] == ' ')
    {
      evt.target.value = evt.target.value.substring(0,evt.target.value.length-1)
    }

    if(evt.target.value[0] == ' ')
    {
      evt.target.value = evt.target.value.substring(1,evt.target.value.length-1)
    }
    evt.target.value=evt.target.value.replaceAll("  "," ")

    var regex = new RegExp("^^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display=""
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display="flex"
    }

    this.verifyState()
  }

  handleAmount(evt){
    evt.target.value=evt.target.value.replaceAll(" ","")
    var amount = evt.target.value

    if(amount.includes(".")){    
      evt.target.value = amount.split(".")[0]
    }

    var regex = new RegExp("^[0-9]{0,}(\.[0-9]{2}){0,1}$");
    if(regex.test(evt.target.value)){
      var currency = document.querySelector(".currency").textContent
      var amount = evt.target.value;
      currency = currency.replaceAll("(","")
      currency = currency.replaceAll(")","")

      console.log("moneda" + currency)

      if((currency.localeCompare("EUR") == 0 || currency.localeCompare("USD") == 0 || currency.localeCompare("GBP") == 0)){        
        if(Number(amount) < .5){          
          evt.target.classList.remove("is-valid")
          evt.target.classList.add("is-invalid")
          evt.target.parentElement.querySelector(".error-message").style.display="flex"
          document.getElementById("amount").parentElement.querySelector(".flag-amount").textContent=".5";
        }else{
          evt.target.classList.add("is-valid")
          evt.target.classList.remove("is-invalid")
          evt.target.parentElement.querySelector(".error-message").style.display=""
        }
      }else{
        if((currency.localeCompare("MXN") == 0 || currency.localeCompare("COP") == 0 || currency.localeCompare("CLP") == 0) && amount < 10){
          evt.target.classList.remove("is-valid")
          evt.target.classList.add("is-invalid")
          evt.target.parentElement.querySelector(".error-message").style.display="flex"
          evt.target.parentElement.querySelector(".error-message b.flag-amount").textContent = "10"
        }else{
          evt.target.classList.add("is-valid")
          evt.target.classList.remove("is-invalid")
          evt.target.parentElement.querySelector(".error-message").style.display=""
        }
      }

    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display="flex"
    }
    this.verifyState()
  }

  handleTelefono(evt){
    evt.target.value = evt.target.value.replaceAll(" ","")

    var regex = new RegExp("^[0-9]{7,}$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display=""
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display="flex"
    }

    this.verifyState()
  }

  handleSelect(evt){
    evt.target.classList.add("is-valid")
    evt.target.classList.remove("is-invalid")

    if (evt.target.value.length == 0){
      evt.target.classList.add("is-invalid")
      evt.target.classList.remove("is-valid");
    }
    document.getElementById("telefono").classList.remove("is-invalid")
    if(evt.target.value.localeCompare("México") == 0){
      document.getElementById("telefono").parentElement.style.display="flex"
      document.getElementById("telefono").classList.remove("is-valid")
    }else{
      document.getElementById("telefono").parentElement.style.display=""
      document.getElementById("telefono").classList.add("is-valid")
    }

    let element= document.querySelector(".currency")

    switch (evt.target.value){
      case "México":
          element.textContent = "(MXN)"
        break;
      case "Italy":
        element.textContent = "(EUR)"
        break;
      case "Spain":
        element.textContent = "(EUR)"
        break;
      case "United Kingdom":
        element.textContent = "(GBP)"
        break;
      case "Colombia":
        element.textContent = "(COP)"
        break;
      case "Chile":
        element.textContent = "(CLP)"
        break;
      case "United States":
        element.textContent = "(USD)"
        break;
    }

    this.verifyState()
  }

  render() {
    return (
      <div className="checkout-container">
        <div className="information-container">
          <img src={logo} alt="stc-logo"/>
          <div className="label-container">

            <h2>
              FUNDACION MEXICANA DE APOYO INFANTIL AC
            </h2>
            <h2 id="description">
            </h2>

            <div className="success"></div>
            <div className="prefijo"></div>
            <div className="datas-container">
              <div className="form-group">
                <label htmlFor="">Country:</label>
                <select name="" id="country" onChange={this.handleSelect.bind(this)}>
                  <option value="">----</option>
                  <option value="México">México</option>
                  <option value="Italy">Italy</option>
                  <option value="Spain">Spain</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Chile">Chile</option>
                  <option value="United States">United States</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="">Amount <b className="currency"></b>:</label>
                <input type="text" id="amount" onBlur={this.handleAmount.bind(this)}/>
                <div className="error-message">
                  <label htmlFor="">Error:</label>
                  <p>
                    -Amount should has just numbers <br/>
                    - Amount should be higher than <b className="flag-amount"></b>

                  </p>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="">Please write your name and last name:</label>
                <input type="text" id="nombre" onBlur={this.handleNombre.bind(this)}/>
                <div className="error-message">
                  <label htmlFor="">Error:</label><br/>
                  <p>
                    -Your name should have only letters. <br/>
                    -You should write your first name and last name.
                  </p>
                </div>
              </div>
              <div className="form-group phone">
                <label htmlFor="">Telephone number:</label>
                <input type="text" id="telefono" onBlur={this.handleTelefono.bind(this)} inputMode="numeric"/>
                <div className="error-message">
                  <label htmlFor="">Error:</label>
                  <p>
                    -Your telephone number should have just numbers. <br/>
                  </p>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="">Email address:</label>
                <input type="email" id="correo" onChange={this.handleCorreo.bind(this)}/>
                <div className="error-message">
                  <label htmlFor="">Error:</label>
                  <p>
                     -Your email shouldn't have any space between letters. <br/>
                     -It must have just one @.
                  </p>
                </div>
              </div>

            </div>


            <button onClick={this.handleClick.bind(this)} className="button-continuar" disabled="disabled">Continue</button>
          </div>
        </div>

        <div className="app-container">

        </div>
      </div>
    );
  }
}